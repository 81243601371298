import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const FixDeposit = () => {


    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])


    
    return (

        <Layout>
            <img src="images/fixed-depostt.jpg" class="banner-img" />
            <div className="container">
                <div className="row container-box">
                    <div className="col-md-12">
                        <div className="tbl-heading">  <strong> Table of Rs. 100000/- FD </strong>   </div>
                    </div>

                    
                    <table className="deposit-tbl table table-bordered table-striped table-responsive">
                        <tbody><tr>
                            <th>Time Period</th>
                            <th>Rate of Interest</th>
                            <th>Maturity Amount</th>
                        </tr>
                            <tr>
                                <td> 6 months</td>
                                <td>6.00 %</td>
                                <td>1,02,995/-</td>
                            </tr>
                            <tr >
                                <td> 12 months</td>
                                <td>6.60 %</td>
                                <td>1,06,760-</td>
                            </tr>
                            <tr>
                                <td> 24 months</td>
                                <td>7.00 %</td>
                                <td>1,14,899/- </td>
                            </tr>
                            <tr >
                                <td> 36 months</td>
                                <td>7.50 %</td>
                                <td>1,24,978/- </td>
                            </tr>
                            <tr>
                                <td> 60 months</td>
                                <td>8.00 %</td>
                                <td>1,48,587/-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row container-box">
                    <div className="col-md-9">

                    <h3 className="depostt-heading"> Fixed Deposit</h3>
                    <p className="deposit-desc">

                    Fixed Deposit in recent times has been considered an excellent mode of wealth building and has gained rapid popularity 
                    as a safe and Secure investment option coupled with rise in income and savings of the public at large.
                     Fixed Deposit have variety of schemes and income options (i.e Fixed deposit, MIS etc) to suit the financial 
                     preferences of all classes of Retail investors, depending on their requirements . Fedora India intends to become a 
                     financial super mart wherein its Members shall be able to participate in a wide range of quality financial schemes under one roof.
                    </p>
                    </div>

                    <div className="col-md-3">
                
                         <img src="./images/Fixed-Deposit.png" alt="fix-deposit" className="fix-deposit-img clipmakers" />
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default FixDeposit;