import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'


const OnlineDeposit = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])
    
    return (
        <Layout>
            <img src="images/legal-baanner.jpg" class="banner-img" />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-box">


                            <div className="online-deposit-heading">
                                <h1 className="text-center">Online Deposit</h1>
                                <p className="online-deposit-desc text-center"> You Can Deposit Amount Now Online </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6">
                        <div className="deposit-box">

                            <img src="./images/SIQRcode_0.png" className="deposit-img"  alt="SIQRcode_0"/>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="deposit-box">

                           <p className="info"> ऑनलाइन भुगतान करें IMPS/NEFT/RTGS के जरीये Pay Online through IMPS/NEFT/RTGS </p>

                           <div className="deposit-list">
                            <ul className="list-heading">
                              <li> A/c Name:- </li>
                              <li> A/c No.:- </li>
                              <li> IFSC:- </li>
                              <li> Branch:- </li>
                            </ul>

                            <ul className="list-info">
                              <li> Fedora India Nidhi Ltd </li>
                              <li> 01200510000564 </li>
                              <li> UCBA0000120 </li>
                              <li> PARLIAMENT STREET, NEW DELHI </li>
                            </ul>
                           
                           </div>
                           <p className="info"> ट्रांसफर करने के बाद अपना कम्पनी का खाता नंबर और अमाउंट डीटेल्स व्हाट्सप्प करे</p>

                           <p className="bold-info"> After transferring the amount please Whatsapp company account number and amount details on +91 99848 40938 or mail at-help@fedoraindia.in</p>

                           <p className="info"> कृपया व्हाट्सएप्प नंबर पर कॉल न करे</p>
                           <div className="last-l"> please don't call on the whatsapp Number </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                         <p className="note"> <span>  Note:- </span>   कोड स्कैन करके पेमेंट जमा करे उसके बाद पेमेंट डिटेल्स का स्क्रीनशॉट एव कम्पनी सदस्य संख्या मोबाइल नंबर +91 99848 40938 पर व्हाट्सप्प करे</p>
                    </div>
                </div>


        
            </div>
        </Layout>
    )
}


export default OnlineDeposit;