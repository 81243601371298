import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const FlexiRd = () => {


    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    return (

        <Layout>
            <img src="images/banner44.jpg" class="banner-img" />
            <div className="container">
                <div className="row container-box">
                    <div className="col-md-6">

                        <div className="flex-rd-box">

                            <h4 className="daily-heading text-center">RATE OF INTEREST</h4>
                            <p className="daily-deposit-desc">Upto  8.50% P.A.</p>
                            <div>
                                <p className="daily-small-info"> <span className="">Loan facility also available against Deposit.</span> </p>
                            </div>
                            <div class="text-center">
                                <button type="button" class="saving-btn">Apply Online</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <img src="./images/fx-vs-LAP.jpg" className="flex-rd-img" />
                    </div>

                </div>

                <div className="row container-box">
                    <div className="col-md-9">

                        <h3 className="depostt-heading"> Fedora Flexi RD </h3>
                        <p className="deposit-desc">

                            Fedora Flexi RD Scheme offers all facilities of a Normal Recurring Deposit along with the flexibility of varying monthly installments for a higher yield on maturity.
                        </p>
                    </div>

                    <div className="col-md-3">

                        <img src="./images/Fixed-Deposit.png" alt="fix-deposit" className="fix-deposit-img clipmakers" />
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default FlexiRd;