import React from "react";
import { Pagination, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Banner = () => {
    return (
        <>


            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,]}
                className="mySwiper"
            >
                <SwiperSlide> <img src="./images/banner.jpg" /></SwiperSlide>
                <SwiperSlide> <img src="./images/banner2.jpg" /></SwiperSlide>
                <SwiperSlide> <img src="./images/banner3.jpg" /></SwiperSlide>


            </Swiper>
        </>
    )
}

export default Banner;