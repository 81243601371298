import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'
import { HiPhone } from "react-icons/hi";
import { FaWhatsapp, FaEnvelope, FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";





const ContactUs = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    
    return (
        <>
            <Layout>

                <div className="contact-banner">
                    <img src="./images/contact-bannner.jpg" alt="contact-banner" className="contact-banner-img" />

                </div>


                <div className="container">
                    <div className="row contact-box">
                        <div className="col-md-6">
                            <h2 className="contact-heading"> M/s Fedora India Nidhi Ltd </h2>
                            <p className="company-address">
                            # B ⅘, 2nd floor , Sector 63, Noida - UP 201301
                              
                              
                            </p>

                            <div className="info-box">
                                <p>  Feel like talking </p>
                                <div className="contact-detail-box">
                                    <div className="contact-icons">
                                        <HiPhone />
                                    </div>
                                    <div className="contact-data">
                                        <a href="tel:9984840938">  +91 9919389552</a>

                                    </div>
                                </div>




                                <div className="contact-detail-box">
                                    <div className="contact-icons">
                                        <FaEnvelope />
                                    </div>
                                    <div className="contact-data">
                                       <a href="mailto: hr@mobcoder.com"> help@fedoraindia.in</a>

                                    </div>
                                </div>


                                <div className="contact-detail-box">
                                    <div className="contact-icons">
                                        <FaWhatsapp /> 
                                    </div>
                                    <div className="contact-data">
                                    <a href="https://wa.me/9984840938?text=How%20are%20you%20?">  +91 : 9984840938 </a> 

                                    </div>
                                </div>

                                <div className="contact-detail-box">

                                   <a href="https://www.facebook.com/official.fedoraindia?mibextid=ZbWKwL"> <FaFacebook />  </a> &nbsp;

                                   <a href="https://www.instagram.com/"> <FaInstagram />  </a> &nbsp;
                                   <a href="https://twitter.com/login"> <FaTwitter />  </a> &nbsp;

                                   <a href="https://www.linkedin.com/a"> <FaLinkedin />  </a>
                                   
                                    {/* &nbsp; <FaInstagram /> &nbsp; <FaTwitter /> &nbsp; <FaLinkedin /> */}

                                </div>

                            </div>

                            <div className="info-box">
                                <div className="contac-timing">
                                    <div className="time-heading">
                                        <strong> Working Hours : </strong>
                                    </div>

                                    <div className="time-desc">
                                        9:00 AM TO 6:00 PM
                                    </div>
                                </div>

                                <div className="contac-timing">


                                    <div className="time-heading">
                                        <strong> Lunch : </strong>
                                    </div>

                                    <div className="time-desc">
                                        1:00 PM TO 2:00 PM
                                    </div>


                                </div>

                                <div className="contac-timing">


                                    <div className="time-heading">
                                        <strong> Weekly OFF :  </strong>
                                    </div>

                                    <div className="time-desc">
                                        Tuesday
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <h6 className="contact-query"> Have any query? </h6>
                            <p className="contact-desc">  If you have questions or comments about this Privacy Policy, please contact us at: </p>

                            <div className="contact-form">
                                <form>
                                    <div class="form-group">

                                        <input type="text" class="form-control" placeholder="Enter Your Name" />
                                    </div>
                                    <div class="form-group">

                                        <input type="text" class="form-control" placeholder="Enter Your Email" />
                                    </div>

                                    <div class="form-group">

                                        <input type="text" class="form-control" placeholder="Enter Your Mobile No" />
                                    </div>

                                    <div class="form-group">

                                        <input type="textarea" class="form-control" placeholder="Enter Your Message" />
                                    </div>

                                    <button className="contact-btn"> Submit </button>



                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14009.885602518774!2d77.3743574!3d28.6156306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5f6844f2a9b%3A0xe9d90c3d7c307da1!2sFedora%20India!5e0!3m2!1sen!2sin!4v1674642486806!5m2!1sen!2sin"  width="100%" height="250" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>


            </Layout>
        </>
    )
}

export default ContactUs;