import React from "react";
import Layout from "../../container/Layout";
import Banner from "./banner";
import Service from "./service";
import Welcome from "./welcome";
import Schema from "./schema";


const Home = () =>{

    return(
        <>
    <Layout>

      <Banner />
      <Service />
      <Welcome />
      <Schema />

    </Layout>
    </>
    )
}


export default Home;