import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const HomeLoan = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    return (
        <Layout>
            <img src="images/slide_22mortage.jpg" class="banner-img" />

            <div className="container">
                <div className="container-box row">
                    

                    <div className="col-md-7">
                    <hr />
                      
                       <h1 className="featured-heading"> Features & Benefits- </h1>

                       <ul className="featured-list mt-5">
                           <li> Maximum Loan Tenure: 7 Years.. </li>
                           <li>  Avail loan up to 50 percent of Project Cost.  </li>
                           <li> Loan available at an attractive ROI of 1.25 % pm. </li>
                           <li> Term Loan and HL overdraft facility available. </li>
                           <li>  Hassle-free quick processing of loans. </li>
                           <li> Minimum documentation and quick disbursal. </li>

                           <li>  Easy Payment Options. </li>
                           <li>  ATL - Anytime Liquidity </li>
                           <li>  In the HL overdraft facility pay only Interest and enjoy the Loan facility.</li>
                       </ul>
                    </div>

                    <div className="col-md-5">
                   
                        <div className="loan-box mt-2">
                            <h3>  Loan Product  </h3>
                            <p className="gold-loan-desc"> Term Loan/Overdraft Facility </p>

                            <div className="interest">
                                <h4 className="interest-heading"> RATE OF INTEREST </h4>
                            </div>

                            <div className="percentage">
                                1.25% P.M.
                            </div>
                            <div>


                                <div className="text-center">
                                    <button className="load-btn"> Apply Online</button>

                                </div>

                            </div>

                        </div>


                    </div>

                    <div className="com-md-12 mt-2">
                       
                        <strong className="bottom-heading"> Conditions apply ( in small size) </strong>
                        
                        
                    </div>

                </div>
            </div>
        </Layout>
    )
}


export default HomeLoan;