import React,{useEffect} from "react";
import Layout from "../../container/Layout";
import './style.css'

const BusinessDeposit = () => {


    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    return (

        <Layout>
            <img src="images/daily-2.jpg" class="banner-img" />
            <div className="container">
                <div className="row container-box">
                <table className="deposit-tbl table table-bordered table-striped table-responsive">
                        <tbody>
                            <tr>
                                <th>Time Period</th>
                                <th>BD Interest Rates</th>
                            </tr>
                            <tr>
                                <td>12-24 Months</td>
                                <td>3 %</td>
                            </tr>
                            <tr >
                                <td>60 Months</td>
                                <td>4 %</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className="row container-box">
                    <div className="col-md-7">
                        <h3 className="saving-heading"> Business Deposit Scheme (B.D.S) </h3>
                        <p className="saving-desc">
                            One can open a Business Deposit with a minimum deposit of Rs 100 per day,
                            which would make Rs 3,000 a month. At maturity i.e. at the end of 5 years, he would have Rs  2,00,000/-
                        </p>
                    </div>
                    <div className="col-md-5">

                        <div className="saving-box">
                            <div class="inner-box ">

                                <h4 className="daily-heading text-center">RATE OF INTEREST</h4>
                                <p className="daily-deposit-desc">Upto  4% P.A.</p>
                                <div>
                                    <p className="daily-small-info"> <span className="">Loan facility also available against Deposit.</span> </p>
                                </div>
                                <div class="text-center">
                                    <button type="button" class="saving-btn">Apply Online</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </Layout>
    )
}

export default BusinessDeposit;