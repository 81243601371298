import React, { useEffect } from "react";
import "./style.css";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="com-md-12">
          <h5 className="about-subheading">PRIVACY POLICY</h5>
          <h5 className="about-subheading">FEDORA INDIA NIDHI LIMITED</h5>
          <br />
          <h6>Privacy Policy</h6>
          <p>
            Mobcoder Technologies has built the Fedora M Banking app as a
            Commercial app. This SERVICE is provided by Mobcoder Technologies
            and is intended for use as is.
            <br />
            This page is used to inform app users regarding our policies with
            the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service.
            <br />
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
            <br />
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at Fedoa India Nidhi
            Limited unless otherwise defined in this Privacy Policy.
          </p>
          <p>1. Information We Collect:</p>
          <ul>
            &#8208; Personal Information: When you register for our M banking
            app, we may collect personal information such as your name, address,
            email address, phone number, and date of birth.
            <br />
            &#8208; Financial Information: We may collect financial information
            such as bank account details, transaction history, and payment card
            information.
            <br />
            &#8208; Device Information: We may collect information about the
            device you use to access our app, including the device model,
            operating system version, unique device identifiers, and IP address.
            <br />
            &#8208; Usage Information: We may collect information about how you
            use our app, including the pages you visit, the features you use,
            and the actions you take.
          </ul>
          <p>2. How We Use Your Information:</p>
          <ul>
            &#8208; To Provide Services: We use your personal and financial
            information to provide you with access to our mobile banking
            services, process transactions, and fulfill your requests.
            <br />
            &#8208; To Improve Our Services: We may use your information to
            analyze usage trends, improve our app's functionality, and develop
            new features.
            <br />
            &#8208; To Communicate With You: We may use your contact information
            to send you important notifications, updates, and marketing
            communications related to our services.
            <br />
            &#8208; To Ensure Security: We may use your information to detect
            and prevent fraud, unauthorized access, and other illegal
            activities.
          </ul>

          <p>3. Data Sharing and Disclosure:</p>
          <ul>
            &#8208; We may share your information with trusted third parties,
            including financial institutions, payment processors, and service
            providers, to facilitate transactions and provide support services.
            <br />
            &#8208; We may disclose your information in response to legal
            requests, court orders, or government regulations, or to protect our
            rights, property, or safety.
            <br />
            &#8208; We may share aggregated or anonymized information that does
            not personally identify you for research, analytics, and marketing
            purposes.
          </ul>
          <p>4. Data Security:</p>
          <ul>
            &#8208; We take reasonable measures to protect your personal
            information from unauthorized access, disclosure, alteration, and
            destruction.
            <br />
            &#8208; We use encryption, access controls, and other security
            technologies to safeguard your data during transmission and storage.
          </ul>
          <p>5. Your Choices:</p>
          <ul>
            &#8208; You can review and update your personal information by
            accessing your account settings within the app.
            <br />
            &#8208; You can opt out of receiving marketing communications from
            us by following the instructions provided in the communication or
            contacting us directly.
          </ul>
          <p>6. Security:</p>
          <ul>
            &#8208; We value your trust in providing us your Personal
            Information, thus we are striving to use commercially acceptable
            means of protecting it. But remember that no method of transmission
            over the internet, or method of electronic storage is 100% secure
            and reliable, and we cannot guarantee its absolute security.
          </ul>
        
          <p>7. Permissions:</p>
          <ul>
            <p>
              Fedora India Nidhi Limited app requires two permissions to work.
            </p>
            <br />
            &#8208; android.permission.INTERNET
            <br />
            <p>
              This permission is required as all the functions in this app
              requires internet connection.
            </p>
            <br />
            &#8208; android.permission.CAMERA
            <p>
              This permission is required to capture images for display
              pictures.
            </p>
            &#8208; android.permission.WRITE_EXTERNAL_STORAGE
            <br />
            <p>This permission is needed to save captured images.</p>
          </ul>
          <p>8. Log Data:</p>
          <ul>
          &#8208;
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            
          </ul>
          <p>9. Service Providers:</p>
          <ul>
            <p>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <br />
            &#8208; To facilitate our Service;
            <br />
            &#8208; To provide the Service on our behalf;
            <br />
            &#8208; To perform Service-related services; or
            <br />
            &#8208; To assist us in analyzing how our Service is used.
            <br />
            <p>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
          </ul>
          <p>10. Icon source:</p>
          <ul>
          &#8208;
              Icons you may find in this app are developed by inhouse team of
              Fedora India.
            
          </ul>
          <p>11. Information Collection and Use:</p>
          <ul>
          &#8208;
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that we request is will be retained
              by us and used as described in this privacy policy. The app does
              use third party services that may collect information used to
              identify you. Link to privacy policy of third party service
              providers used by the app § Google Play Services
            
          </ul>
          <p>12. Children's Privacy:</p>
          <ul>
            &#8208; These Services do not address anyone under the age of 13. We
            do not knowingly collect personally identifiable information from
            children under 13. In the case we discover that a child under 13 has
            provided us with personal information, we immediately delete this
            from our servers. If you are a parent or guardian and you are aware
            that your child has provided us with personal information, please
            contact us so that we will be able to do necessary actions.
          </ul>
          <p>13. Changes to This Privacy Policy:</p>
          <ul>
            &#8208; We may update our Privacy Policy from time to time. Thus,
            you are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Privacy Policy on
            this page. These changes are effective immediately after they are
            posted on this page.
          </ul>
          <p>14. Updates to This Policy:</p>
          <ul>
            &#8208; We may update this Privacy Policy from time to time to
            reflect changes in our practices or legal requirements. We will
            notify you of any material changes by posting the updated policy on
            our website or through the app.
          </ul>
          <p>15. Contact Us:</p>
          <ul>
            &#8208; If you have any questions or concerns about this Privacy
            Policy or our data practices, please contact us at email:
            <a href="/contact-us"> Help@fedoraindia.in </a> & Mb. 9984840938.
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
