import React from "react";

const Welcome = () => {
    return (
        <>
            <div className="welcome-box">


                <div className="container mt-3">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="welcome-img-box">
                                <img src="./images/side-img.jpg" className="welcome-img" />
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="welcome-content-box">
                                <h3 className="welcome-heading">  <span style={{ color: '#fff' }}>  Why Choose Us? </span>  M/s Fedora India Nidhi Ltd .  </h3>
                                
                                <p className="welcome-desc">
                                    Online Fund Transfer/ Withdrawal/ Deposit Facility.
                                </p>
                                <p className="welcome-desc">
                                    Doorstep Service Like Delivery/Pickup Of Cash/Cheque.
                                </p>

                                <p className="welcome-desc">
                                     Quick Approval of Loans With No Hidden Charges.
                                </p>
                                <p className="welcome-desc">
                                     Wide Range Of Banking Products.
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Welcome;