import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const SavingAccount = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (

        <Layout>
            <img src="images/saving.jpg" class="banner-img" />
            <div className="container">
                <div className="row container-box">
                    <div className="col-md-12">
                        <p className="account-desc"> Saving money habit will improve the quality of life and will help you to feel better and to sleep better.
                            One will be happier knowing he/she has some money aside for unexpected expenses and look forward
                            to achieve some of their dreams.FEDORA INDIA Nidhi LTD.
                            has introduced uniquely designed “Saving Account” to inbuilt the habit of saving among people.</p>
                        <hr />
                    </div>

                    <table className="deposit-tbl table table-bordered table-striped table-responsive">
                        <h3 className="saving-feature-heading">  Feature of Saving Account </h3>
                        <tbody>
                            <tr>
                                <th >Main Features</th>
                                <th >Saving Account</th>
                            </tr>
                            <tr>
                                <td>Minimum Deposit</td>
                                <td>2000 only</td>
                            </tr>
                            <tr>
                                <td>Nomination Facility</td>
                                <td><i class="fa fa-check fa-lg"></i></td>
                            </tr>
                            <tr>
                                <td>Attractive Rate of Interest</td>
                                <td>4%</td>
                            </tr>
                            <tr >
                                <td>SMS Facility</td>
                                <td><i class="fa fa-check fa-lg"></i></td>
                            </tr>
                            <tr>
                                <td colspan="2">NO TDS IS APPLICABLE ON THE INTEREST EARNED ON DEPOSITS.</td>
                            </tr>
                            <tr>
                                <td colspan="2" > OFFERS HIGHER INTEREST RATES. </td></tr>
                            <tr>
                                <td colspan="2"> SAVING ACCOUNT PASSBOOK WILL BE PROVIDED. </td></tr>
                            <tr>
                                <td colspan="2"> ZERO BALANCE ACCOUNT </td></tr>
                            <tr>
                                <td colspan="2"> ANY TIME DEPOSIT AND WITHDRAWAL. </td></tr>
                            <tr>
                                <td colspan="2" > MINER SAVING ACCOUNT. </td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="row container-box">
                    <div className="col-md-9">

                        <h3 className="saving-feature-heading"> Salient Features </h3>

                        <ol style={{ textAlign: 'left' }}>
                            <li>Nomination facility available for deposits.</li>
                            <li>There can be only one Nominee for a deposit account whether held singly or jointly.</li>
                            <li>A person legally empowered to operate a minor's account can file a nomination on behalf of the minor.
                            </li>
                            <li>Applicants can make nomination by filling up the prescribed form.</li>
                            <li>The nomination details can be changed during the subsistence of the account relationship by filling up
                                prescribed form.</li>
                            <li>For more details approach your branch.</li>
                        </ol>

                    </div>

                    <div className="col-md-3">
                        <img src="./images/Recurring-Fixed.png" alt="fix-deposit" className="fix-deposit-img clipmakers" />
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default SavingAccount;