import React from "react";
import { Link } from "react-router-dom";

const Schema = () => {
    return (
        <>
            <div className="schema-box">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">


                            <h3 className="schema-heading"> ATTRACTIVE SCHEMES </h3>
                            <h3 className="schema-sub-heading"> <strong> OUR LOAN </strong>  </h3>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <Link to="/home-loan"><img src="./images/4.jpg" alt="first-image" className="schema-img" /></Link>
                            <h4 className="schema-fouth-heading"> Home Loan </h4>
                        </div>
                        <div className="col-md-2">
                            <Link to="/loan-against-deposit"><img src="./images/2.jpg" alt="first-image" className="schema-img" /></Link>
                            <h4 className="schema-fouth-heading"> Loan Against Deposit </h4>
                        </div>
                        <div className="col-md-4">
                            <Link to="/gold-loan"><img src="./images/3.jpg" alt="first-image" className="schema-img big-shape-img" /></Link>
                            <h4 className="schema-fouth-heading other-heading"> Gold Loan </h4>
                        </div>
                        <div className="col-md-2">
                            <Link to="/other-loan"><img src="./images/5.jpg" alt="first-image" className="schema-img" /></Link>
                            <h4 className="schema-fouth-heading"> Other Loan  </h4>
                        </div>
                        <div className="col-md-2">
                            <Link to="/business-loan"><img src="./images/1.jpg" alt="first-image" className="schema-img" /></Link>
                            <h4 className="schema-fouth-heading"> Business Loan  </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Schema;