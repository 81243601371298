import React,{useEffect} from "react";
import Layout from "../../container/Layout";
import './style.css'

const RecurringDeposit = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])



    return (

        <Layout>
            <img src="images/recurringgbanner.jpg" class="banner-img" />
            <div className="container">
                <div className="row container-box">
                    <div className="col-md-12">
                        <div className="tbl-heading">  <strong> Table of Rs. 10,000/- P.M.  </strong>   </div>
                    </div>


                    <table className="deposit-tbl table table-bordered table-striped table-responsive">
                        <tbody><tr>
                            <th>Monthly Deposit (Rs.)</th>
                            <th>Time Period</th>
                            <th>RD Interest Rates</th>
                            <th>Maturity</th>
                        </tr>
                            <tr>
                                <td>10000</td>
                                <td>12 Months</td>
                                <td>6.60 %</td>
                                <td>1,24,370/-</td>
                            </tr>
                            <tr >
                                <td>10000</td>
                                <td>24 Months</td>
                                <td>7.00 %</td>
                                <td>2,58,258/-</td>
                            </tr>
                            <tr>
                                <td>10000</td>
                                <td>36 Months</td>
                                <td>7.50 %</td>
                                <td>4,04,606/-</td>
                            </tr>
                            <tr >
                                <td>10000</td>
                                <td>60 Months</td>
                                <td>8.00 %</td>
                                <td>7,38,701/-</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="row container-box">
                    <div className="col-md-9">

                        <h3 className="depostt-heading"> Recurring Deposit </h3>
                        <p className="deposit-desc">


                            Recurring Deposit in recent times has been considered an excellent mode of wealth
                            building and has gained rapid popularity as a safe and Secure investment option coupled with rise in income and savings of
                            the public at large. Recurring Deposit have only one scheme options (i.e Recurring Deposit Plan) to suit the financial preferences of all
                            classes of Retail investors, depending on their requirements. Fedora India intends to become a financial supermart wherein its Members
                            shall be able to participate in a wide range of quality financial schemes under one roof.
                        </p>
                    </div>

                    <div className="col-md-3">

                        <img src="./images/Recurring-Fixed.png" alt="fix-deposit" className="fix-deposit-img clipmakers" />
                    </div>

                    <div className="text-center">
                        <button className="saving-btn"> Apply Online </button>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default RecurringDeposit;