import React, { useEffect } from "react";
import Layout from "../../container/Layout";

const NoPage = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    return (
        <>
            <Layout>
                <img src="images/not-found.png" style={{ width: '100%' }} />
            </Layout>
        </>
    )
}


export default NoPage;