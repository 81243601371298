import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './style.css'
import { HiOutlineMenu } from "react-icons/hi";

const Header = () => {

    const [stickyClass, setStickyClass] = useState(false);
    const [toggle, setToggle] = useState(false);


    const controlNavBar = () => {

        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 50 ? setStickyClass(true) : setStickyClass(false);
        }

      
    }

    useEffect(() => {

        window.addEventListener('scroll', controlNavBar);
        return () => {
            window.removeEventListener('scroll', controlNavBar)
        }

    }, [])



    const handleToggle = () => {

        setToggle(!toggle);
       

    }

 let menuToggle = toggle ? 'none-div' : ''

    return (
        <>
            <header>


               
                {/* responsive enu  */}




                    <div className="mobile-menu">
                        <div className="logo-div">

                            <Link to="/">
                                <img src="./images/logo.png" className="logo" alt="Logo" class="logo-img" />
                            </Link>

                        </div>

                        <div className="menu-toggle">

                            <span className="menu-bar" onClick={handleToggle}> <HiOutlineMenu />  </span>

                        </div>
                    </div>
                    
                


                {/* desktop menu  */}



                <div className="container desktop-header">
                    <div className="row mt-3">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-4">
                            <Link to="/">
                                <img src="./images/logo.png" className="logo" alt="Logo" style={{ width: "278px" }} />
                            </Link>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-8">
                            <center>
                                <div className="right-box">
                                    <marquee behavior="" direction="">
                                        <a href="#" className="botton" style={{ fontSize: "20px" }}>
                                            100%
                                            Secure &amp; guaranteed returns, avail savings account with highest interest rates.
                                        </a>
                                    </marquee>

                                </div>

                            </center>



                        </div>
                    </div>
                </div>



                <nav className={stickyClass ? 'fix-navigation ' : 'navbg'} >
                    <div className={`${menuToggle}  ${!toggle ? 'hide-nav' : ''} `}>

                    
                    <div className="container ">
                        <ul class="main-nav-list">
                            <li>
                                <Link to="/" class="main-nav-link"> <span> <img src="./images/home.png" alt="home" className="menu-icon" /> </span>  Home </Link>
                            </li>
                            <li>
                                <Link to="/about-us" class="main-nav-link"> <span> <img src="./images/user.png" alt="home" className="menu-icon" /> </span>  About </Link>
                            </li>

                            <li>
                                <a href="#" class="main-nav-link"> <span> <img src="./images/save-money.png" alt="Save" className="menu-icon" /> </span> Save </a>

                                <ul className="submenu">


                                    <li><Link to="/fixed-deposit">Fixed Deposit Plan</Link></li>
                                    <li><Link to="/recurring-deposit">Recurring Deposit Plan</Link></li>
                                    <li><Link to="/business-deposit">Business Deposit</Link></li>
                                    <li><Link to="/saving-account">Saving Account</Link></li>
                                    <li><Link to="/flexi-rd">Fedora Flexi RD Account</Link></li>
                                </ul>
                            </li>

                            <li>

                                <a href="#" class="main-nav-link"> <span> <img src="./images/save-money.png" alt="Save" className="menu-icon" /> </span> Borrow </a>

                                <ul className="submenu">
                                    <li> <Link to="/gold-loan">Gold Loan</Link> </li>
                                    <li><Link to="/home-loan">Home Loan</Link></li>
                                    <li><Link to="/loan-against-deposit">Loan Against Deposit </Link></li>
                                    <li><Link to="/business-loan">Business Loan</Link></li>
                                    <li><Link to="/other-loan">Other Loan</Link></li>
                                </ul>
                            </li>

                            <li>
                                {/* <Link to="/online-deposit" class="main-nav-link nav-act"> <span> <img src="./images/web.png" alt="Online Service" className="menu-icon" /> </span> Online Service </Link> */}
                                <a href="#" class="main-nav-link"> <span> <img src="./images/save-money.png" alt="Save" className="menu-icon" /> </span> Online Service </a>

                                <ul className="submenu">
                                    <li> <Link to="/online-deposit"> Online Deposit </Link> </li>
                                    <li><a href="https://web.fedoraindia.in/login" target="_blank" rel="noopener noreferrer">
                                        E-banking Login</a></li>

                                </ul>
                            </li>

                            <li>
                                <Link to="/contact-us" class="main-nav-link nav-act"> <span> <img src="./images/notebook-of-contacts.png" alt="home" className="menu-icon" /> </span> Contact us </Link>
                            </li>
                        </ul>
                    </div>
                    </div>

                </nav>

            </header>
        </>
    )
}


export default Header