import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const OtherLoan = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <Layout>
            <div className="other-loan-box">
                <h3 className="other-loan-heading"> Other Loan </h3>
            </div>

            <div className="container">

                <div className="container-box row">

                    <div className="col-md-7 ">
                        <p className="other-desc"> An amount given to an individual to use for personal benefit (Medical, education, vacation, extension & repair of house hold etc. That must be paid off at a specific time.</p>
                        <h1 className="featured-heading mt-5"> Features & Benefits- </h1>

                        <ul className="featured-list mt-5">

                            <li> <span className="other-icon"> ➤ </span> Loan available at attractive ROI of 1.32 % pm. </li>
                            <li> <span className="other-icon"> ➤ </span>  Term Loan facility available. </li>
                            <li>  <span className="other-icon"> ➤ </span> Hassle-free quick processing of loans. </li>
                            <li> <span className="other-icon"> ➤ </span>  Minimum documentation and quick disbursal. </li>
                            <li> <span className="other-icon"> ➤ </span>   Easy Payment options. </li>
                            <li>  <span className="other-icon"> ➤ </span>ATL - Anytime Liquidity </li>
                        </ul>
                    </div>

                    <div className="col-md-5">
                        <div className="loan-box">
                            <h3> Loan Product  </h3>
                            <p className="gold-loan-desc">  Term Loan </p>

                            <div className="interest">
                                <h4 className="interest-heading"> RATE OF INTEREST </h4>
                            </div>

                            <div className="percentage">
                                1.32% P.M.
                            </div>

                            <div>
                                <div className="text-center">
                                    <button className="load-btn"> Apply Online</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}


export default OtherLoan;