import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const LoanAgaints = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])
    

    return (
        <Layout>
            <img src="images/Property-loan-banner.jpg" class="banner-img" />

            <div className="container">
                <div className="container-box row">

                    <div className="col-md-7">
                       <h1 className="featured-heading"> Features & Benefits- </h1>

                       <ul className="featured-list mt-5 ">
                           <li> Loan Allowed Up To 90% Of Outstanding Deposit Value. </li>
                           <li> Maximum Loan Tenure The Period Of Loan Shall Not Exceed The Unexpired Period of The Deposits. </li>
                           <li> Hassle-free quick processing of loans. </li>
                           <li> Minimum documentation and quick disbursal. </li>
                           <li>  Easy Payment options. </li>
                           <li> ATL - Anytime Liquidity </li>
                           <li> No EMI, Service only Interest and enjoy the Loan facility </li>
                           <li> Processing Charges Waved. </li>
                       </ul>
                    </div>

                    <div className="col-md-5">
                        <div className="loan-box">
                            <h3> Loan Product  </h3>
                            <p className="gold-loan-desc">  Term Loan/Overdraft Facility </p>

                            <div className="interest">
                                <h4 className="interest-heading"> RATE OF INTEREST </h4>
                            </div>

                            <p>
                            <span className="big-text"> 0.33% P.M. </span>  <span className="small-text"> Above the deposit rate.</span>
                            </p>
                           
                            <div>


                                <div className="text-center">
                                    <button className="load-btn"> Apply Online</button>

                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </Layout>
    )
}


export default LoanAgaints;