import React from "react";

const Service = () => {
    return (
        <>
            <div className="container">

                <div className="row">
                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Gold Loan Plans </h1>
                            <div className="service-box">
                                <img src="./images/loan-img.png" class="service-img" alt="service-image1" />
                                <p className="service-description">
                                    <strong>
                                        Gold Loans enables customers to avail a general purpose loan against the pledge
                                        of gold ornaments to meet immediate and short term requirements.
                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/gold-loan" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Home Loan </h1>
                            <div className="service-box">
                                <img src="./images/home-img.png" class="service-img" alt="service-image1" />
                                <p className="service-description">
                                    <strong>
                                        There are times when you want funds but are stuck because your earnings &
                                        savings are invested into one or more assets - one of the favourite assets being Properties.
                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/home-loan" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Recurring Deposits </h1>
                            <div className="service-box">
                                <img src="./images/Recurring-img.png" class="service-img" alt="service-image1" />
                                <p className="service-description">
                                    <strong>
                                        A Reccuring deposit (RD) is a financial instrument provided by Indian banks which provides investors with a higher rate of interest.


                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/recurring-deposit" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>


                </div>


                <div className="row mt-3">
                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Fixed Deposit </h1>
                            <div className="service-box">
                                <img src="./images/Deposit-img.png" class="service-img" alt="Deposit-img" />
                                <p className="service-description">
                                    <strong>
                                        Depositors can choose from a wide range of deposit products with maturities ranging from 06 to 60 months at competitive rates of
                                        interest and with different features to suit the investment needs of individual Members...
                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/fixed-deposit" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Savings Deposits  </h1>
                            <div className="service-box">
                                <img src="./images/Savings-img.png" class="service-img" alt="Savings-img" />
                                <p className="service-description">
                                    <strong>
                                        Fedora India Ltd. Saving account encourages savings habit among salary earners and others who have fixed income.
                                        It enables the depositor to earn income by way of highest interest.
                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/saving-account" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="our-services">
                            <h1 className="service-heading"> Fedora Flexi RD Account </h1>
                            <div className="service-box">
                                <img src="./images/Monthly-img.png" class="service-img" alt="Monthly-img" />
                                <p className="service-description">
                                    <strong>
                                        Fedora Flexi RD Scheme offers all facilities of a Normal Recurring Deposit along with the flexibility of varying monthly installments for a higher yield on maturity.
                                    </strong>
                                </p>
                            </div>
                            <div className="read-more ">
                                <span> &nbsp;</span>
                                <a href="/flexi-rd" className="service-info"> Read More</a>
                            </div>
                        </div>
                    </div>


                </div>




            </div>
        </>
    )
}

export default Service;