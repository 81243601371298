import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'

const BusinessLoan = () => {


    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])

    
    return (
        <Layout>
            <img src="images/Daily-Depoosit-banner.jpg" class="banner-img" />

            <div className="container">
                <div className="container-box row">

                    <div className="col-md-7 mt-2">
                       <h1 className="featured-heading"> Features & Benefits- </h1>

                       <ul className="featured-list mt-5">
                           <li> Target Group: Existing members having deposit accounts with us. </li>
                           <li> Loan available at an attractive ROI of 1.10 % pm. </li>
                           <li> Term Loan and overdraft facility available. </li>
                           <li> Hassle-free quick processing of loans. </li>
                           <li>  Minimum documentation and quick disbursal. </li>
                           <li> Easy Payment options. </li>
                           <li>  ATL - Anytime Liquidity </li>
                           <li>  No EMI, Service only Interest and enjoy the Loan facility </li>
                       </ul>
                    </div>

                    <div className="col-md-5">
                        <div className="loan-box">
                            <h3> Loan Product  </h3>
                            <p className="gold-loan-desc">  Demand Loan/Overdraft Facility </p>

                            <div className="interest">
                                <h4 className="interest-heading"> RATE OF INTEREST </h4>
                            </div>

                            <div className="percentage">
                            1.10% P.M.
                            </div>
                            <div>


                                <div className="text-center">
                                    <button className="load-btn"> Apply Online</button>

                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </Layout>
    )
}


export default BusinessLoan;