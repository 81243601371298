import "./App.css";
import "./assets/css/header.css";
import "./assets/css/nav.css";
import "./assets/css/service.css";
import "./assets/css/welcome.css";
import "./assets/css/schema.css";
import "./assets/css/footer.css";
import "./assets/css/query.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./component/page-not-found";
import AboutUs from "./component/about";
import ContactUs from "./component/contact";
import Home from "./component/home";
import OnlineDeposit from "./component/online-deposit";
import GoldLoan from "./component/gold-loan";
import HomeLoan from "./component/home-loan";
import BusinessLoan from "./component/business-loan";
import LoanAgaint from "./component/loan-against";
import OtherLoan from "./component/other-loan";
import FixDeposit from "./component/save/FixDeposit";
import RecurringDeposit from "./component/save/RecurringDeposit";
import BusinessDeposit from "./component/save/BusinessDeposit";
import SavingAccount from "./component/save/SavingAccount";
import FlexiRd from "./component/save/FlexiRd";
import Privacy from "./component/privacy";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy_policy.html" element={<Privacy />} />
          <Route path="/fixed-deposit" element={<FixDeposit />} />
          <Route path="/recurring-deposit" element={<RecurringDeposit />} />
          <Route path="/business-deposit" element={<BusinessDeposit />} />
          <Route path="/saving-account" element={<SavingAccount />} />
          <Route path="/flexi-rd" element={<FlexiRd />} />
          <Route path="/online-deposit" element={<OnlineDeposit />} />
          <Route path="/gold-loan" element={<GoldLoan />} />
          <Route path="/home-loan" element={<HomeLoan />} />
          <Route path="/loan-against-deposit" element={<LoanAgaint />} />
          <Route path="/business-loan" element={<BusinessLoan />} />
          <Route path="/other-loan" element={<OtherLoan />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" exact={true} element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
