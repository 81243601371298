import React, { useEffect } from "react";
import Layout from "../../container/Layout";
import './style.css'


const AboutUs = () => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    },[])
    
    return (
        <Layout>
            <div className="about-banner">
                <img src="./images/about-banner.jpg" alt="about-banner" className="about-banner-img" />
            </div>

            <div className="container">
                <div className="row">
                    <div className="com-md-12">
                        <div className="about-box">
                            <h1 className="about-heading">  Welcome To </h1>
                            <h3 className="about-subheading">  <strong>  FEDORA INDIA NIDHI LTD. </strong> </h3>
                            <p className="about-desc">
                                <strong> FEDORA INDIA NIDHI LTD. </strong> being a company registered under the Companies Act 2013, it automatically comes under the supervision of the MCA. Main objective is to encourage and afford all facilities for cultivating thrift ,saving habits and to render all financial assistance to its member only by receiving long and short term deposits and in particular recurring ,fixed and other deposits, and to grant loans to the members only as against securities of immovable properties (within City) and or on the security of deposits, movable such as gold, jewellery, Kisan Vikas Patra, National Saving Certificates Scheme, insurance policies and other Government securities up on such terms and condition as may from time to time prescribed in law for Nidhi or Mutual Benefits Companies.

                                A company declared as a Nidhi or Mutual Benefit Society registered under the section 406 of the Companies Act, 2022. Nidhi is have many features which make them stand distinctly apart from many other Non-Banking financial company who are working under the guideline of R.B.I
                            </p>

                        </div>

                        <div className="about-box">
                            <h2>  The Company  </h2>

                            <p className="company-desc">
                                <strong > FEDORA INDIA NIDHI LTD. </strong>  Is Nidhi Ltd. Company Founded By Sh. Girijesh Kumar (Director) And Sh. Ashutosh Kr Singh Along With Other 5 Key Members And Registered Under Section 18 Of The Companies Act 2013. The Corporate Identity Number Of The Company Is U65999UP2021PLN154034. The Company Was Registered With The Registrar Of Companies, KANPUR On 18th Of October 2021. The Primary Objective Of The Fedora India is To Uplift The Social And Economic Conditions Of Its Members By Promoting Saving Habits. The Products Of The Company Are In The Shape Of Daily Deposit, Fixed Deposits, Recurring Deposits, Saving Accounts And Loans.
                            </p>

                        </div>

                        <div className="about-box">
                            <h4 className="customer-expect-heading"> WE ALWAYS TRY TO UNDERSTAND CUSTOMERS EXPECTATION </h4>
                            <p className="sustomer-axpect-desc">
                                Fedora India has its corporate office at B-4/5, Sector 63, Noida UP 201301 . The norms and rules of working related to the Company have been outlined under the Bye-laws of the Fedora India. Fedora India strictly abides by the Section 18 of the Companies Act, 2013, and conducts its business amongst the members of the Company. The Company uses the funds for loans to its members and for investments as per RBI, SEBI and Companies Act / Rules / Bye-laws. The registration, guiding and supervising authority of the Company is the Registrar of Companies, Kanpur.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs;