import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";

const Footer = () => {
    return (
        <>

            <div className="footer-wrapper">

                <div class="container">
                    <div class="footer_div">

                        <div class="link">
                            <h3>SITE LINKS</h3>
                            <Link to="/about-us"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Company Profile</Link>
                            <Link to="/online-deposit"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Online Deposit</Link>


                            <Link to="/contact-us"> <span className="arrow-icon"> <HiOutlineChevronRight />  </span> Contact Us</Link>
                            <Link to="/privacy_policy.html"> <span className="arrow-icon"> <HiOutlineChevronRight />  </span> Privacy Policy </Link>
                        </div>
                        <div class="link">
                            <h3>SAVE</h3>
                            <Link to="/fixed-deposit"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Fixed Deposit Plan</Link>
                            <Link to="/recurring-deposit"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Recurring Deposit Plan</Link>

                            <Link to="/business-deposit"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Business Deposit</Link>

                            <Link to="/saving-account"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Saving Account</Link>
                            <Link to="/flexi-rd"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Fedora Flexi RD Account</Link>
                        </div>
                        <div class="link">
                            <h3>BORROW</h3>
                            <Link to="/gold-loan"> <span className="arrow-icon"> <HiOutlineChevronRight />  </span>  Gold Loan</Link>
                            <Link to="/home-loan"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Home Loan</Link>
                            <Link to="/loan-against-deposit"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Loan Against Deposit </Link>
                            <Link to="/business-loan"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Business Loan </Link>
                            <Link to="/other-loan"><span className="arrow-icon"> <HiOutlineChevronRight />  </span> Other Loan</Link>
                        </div>

                        <div class="link">
                            <h3>GET IN TOUCH</h3>
                            <p>
                                <strong> M/s Fedora India Nidhi Ltd .</strong><br />
                                Sector 63, Noida
                                <br />
                                UP 201301


                            </p>
                            <p><i class="fa fa-envelope" aria-hidden="true"></i> help@fedoraindia.in
                            </p>
                            <p><i class="fa fa-phone" aria-hidden="true"></i> +91 9984840938
                            </p>
                        </div>
                    </div>
                    <div>
                        <h3>DOWNLOAD FROM HERE</h3>
                        <a
                            href="https://drive.google.com/file/d/1oQyM5FwlCwzlaCj1b45Fh3XusutjyKFI/view"
                            class="download-link"
                        >
                            <span>
                                <img src="./logo192.png" alt="Save" class="menu-icon" />
                            </span>
                            <span class="download-text">
                                <span class="get-it-on-text">Get it on</span>
                                <span class="app-name">Fedora Nidhi mBanking</span>
                            </span>
                        </a>
                    </div>

                </div>
                <div class="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">  <strong> Copyright &copy; 2022 FEDORAINDIA. all rights reserved. </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Footer